import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.trim.js";
export default {
  __name: 'StickyAside',
  props: {
    offsetTop: {
      type: Number,
      default: null
    },
    offsetBottom: {
      type: Number,
      default: 0
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var defOffsetTop = ref(56);
    var offset = computed(function () {
      return props.offsetTop === null ? defOffsetTop.value : props.offsetTop;
    });
    var element = ref(null);
    var computedStyle = ref("position: sticky; top: ".concat(offset.value, "px"));

    // Calculate top menu height
    onMounted(function () {
      var menuHeight = window.getComputedStyle(document.documentElement).getPropertyValue("--top-bar-height");
      var values = menuHeight.replace(/calc\(|\)/g, '').split('+').map(function (value) {
        return value.trim();
      });
      defOffsetTop.value = values.reduce(function (acc, value) {
        var numericValue = parseInt(value);
        return acc + numericValue;
      }, 0);
    });
    var topVisible = true;
    var bottomVisible = true;
    var scrollDirection = 'down';
    var lastScrollTop = 0;
    var rect = null;
    var isSidebarVisible = true;
    function detectScrollDirection() {
      var scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) scrollDirection = 'down';else if (scrollTop < lastScrollTop) scrollDirection = 'up';
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    }
    function getComputedStyle() {
      if (topVisible && bottomVisible || scrollDirection === 'up' && topVisible) {
        return "position: sticky; top: ".concat(offset.value, "px");
      }
      if (scrollDirection === 'down' && bottomVisible) {
        var _top = window.innerHeight - rect.height + props.offsetBottom;
        return "position: sticky; top: ".concat(_top, "px");
      }
      var scrollTop = window.scrollY || document.documentElement.scrollTop;
      var top = scrollTop + Math.round(rect.top) - offset.value;
      return "position: relative; top: ".concat(top, "px");
    }
    var isTicking = false;
    function updateState() {
      if (isTicking || !isSidebarVisible) return;
      isTicking = true;
      requestAnimationFrame(function () {
        detectScrollDirection();
        rect = element.value.getBoundingClientRect();

        // Math.round fixes jumping behavior on iOS 
        topVisible = Math.round(rect.top) - offset.value >= 0;
        bottomVisible = Math.round(rect.bottom) + props.offsetBottom <= window.innerHeight;
        var newStyle = getComputedStyle();
        if (computedStyle.value !== newStyle) computedStyle.value = newStyle;
        isTicking = false;
      });
    }
    function handleResize() {
      var isVisibleNow = checkVisibility();
      if (isVisibleNow !== isSidebarVisible) {
        isSidebarVisible = isVisibleNow;
        if (isSidebarVisible) {
          updateState();
          window.addEventListener("scroll", updateState, false);
        } else {
          window.removeEventListener("scroll", updateState);
        }
      }
    }
    function checkVisibility() {
      var sidebar = document.querySelector('.sidepane-wrapper');
      return sidebar && window.getComputedStyle(sidebar).display !== 'none';
    }
    onMounted(function () {
      // Check if sidebar is initially visible
      isSidebarVisible = checkVisibility();
      if (isSidebarVisible) {
        window.addEventListener("scroll", updateState, false);
      }
      window.addEventListener("resize", handleResize, false);
    });
    onBeforeUnmount(function () {
      if (isSidebarVisible) {
        window.removeEventListener("scroll", updateState);
      }
      window.removeEventListener("resize", handleResize);
    });
    return {
      __sfc: true,
      props: props,
      defOffsetTop: defOffsetTop,
      offset: offset,
      element: element,
      computedStyle: computedStyle,
      topVisible: topVisible,
      bottomVisible: bottomVisible,
      scrollDirection: scrollDirection,
      lastScrollTop: lastScrollTop,
      rect: rect,
      isSidebarVisible: isSidebarVisible,
      detectScrollDirection: detectScrollDirection,
      getComputedStyle: getComputedStyle,
      isTicking: isTicking,
      updateState: updateState,
      handleResize: handleResize,
      checkVisibility: checkVisibility
    };
  }
};