import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.timers.js";
import CasesContent from '~/components/CasesContent.vue';
import CasesMenu from '~/components/CasesMenu.vue';
export default {
  components: {
    CasesContent: CasesContent,
    CasesMenu: CasesMenu,
    Toasts: function Toasts() {
      return import('~/components/toasts/Toasts.vue');
    },
    BoostPanel: function BoostPanel() {
      return import('~/components/panels/BoostPanel.vue');
    },
    FeedSocket: function FeedSocket() {
      return import('~/components/feed/Socket.vue');
    }
  },
  head: function head() {
    var description = this.$t('head.cases.description');
    var title = this.$t('head.cases.title');
    var image = "".concat(process.env.baseUrl, "/images/CASES-OG.png");
    var url = "".concat(process.env.baseUrl).concat(this.$nuxt.$route.path);
    var viewportSetting = 'width=device-width, initial-scale=1, viewport-fit=cover';
    if (this.appStore.isStandaloneApp) viewportSetting += ', maximum-scale=1.0, user-scalable=no';
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
        'data-theme': this.theme
      },
      title: title,
      meta: [{
        charset: 'utf-8'
      }, {
        name: 'viewport',
        content: viewportSetting
      }, {
        name: "google-site-verification",
        content: "veOw5u6tiz_rjuCgicnUAcNCIVI5CBBKhemR7-eyK7E"
      }, {
        name: "apple-mobile-web-app-title",
        content: "CASES"
      },
      // Window tinting
      // { hid: 'chrome-titnting', name:"theme-color", content:"#0E1014" },

      {
        hid: 'safari-titnting',
        name: "apple-mobile-web-app-status-bar-style",
        content: "black-translucent"
      },
      // { hid: 'mobile-webapp', name:"mobile-web-app-capable", content:"yes" },
      {
        hid: 'apple-webapp',
        name: "apple-mobile-web-app-capable",
        content: "yes"
      },
      // Primary Meta Tags
      {
        hid: 'title',
        name: "title",
        content: title
      }, {
        hid: 'description',
        name: "description",
        content: description
      }, {
        hid: 'description',
        name: "image",
        content: image
      },
      // Open Graph / Facebook
      {
        property: "og:site_name",
        content: "CASES"
      }, {
        hid: "og:type",
        property: "og:type",
        content: "website"
      }, {
        hid: "og:url",
        property: "og:url",
        content: url
      }, {
        hid: "og:title",
        property: "og:title",
        content: title
      }, {
        hid: "og:description",
        property: "og:description",
        content: description
      }, {
        hid: "og:image",
        property: "og:image",
        content: image
      },
      // Twitter
      {
        hid: "twitter:card",
        name: "twitter:card",
        content: "summary_large_image"
      }, {
        hid: "twitter:url",
        name: "twitter:url",
        content: url
      }, {
        hid: "twitter:title",
        name: "twitter:title",
        content: title
      }, {
        hid: "twitter:description",
        name: "twitter:description",
        content: description
      }, {
        hid: "twitter:image",
        name: "twitter:image",
        content: image
      }

      // { hid: "theme-color-light",  name: "theme-color", media: "(prefers-color-scheme: light)", content: "#ffffff" },
      // { hid: "theme-color-dark",   name: "theme-color", media: "(prefers-color-scheme: dark)",  content: "#1f2022" },
      ],
      link: [
      // Icons
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico'
      }, {
        rel: "apple-touch-icon",
        sizes: "180x180",
        href: "/static/apple-touch-icon-gradient.png"
      }, {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: "/static/favicon-32x32.png"
      }, {
        rel: "icon",
        type: "image/png",
        sizes: "16x16",
        href: "/static/favicon-16x16.png"
      }, {
        rel: "mask-icon",
        href: "/static/safari-pinned-tab.svg",
        color: "#000000"
      }, {
        rel: "manifest",
        href: "/manifest.webmanifest",
        hid: "manifest"
      }, {
        rel: "preload",
        href: "/fonts/cases-iconsv1.woff?v=se8fj82",
        as: "font",
        crossorigin: "anonymous"
      }, {
        rel: "dns-prefetch",
        href: "https://connect.facebook.net/"
      }, {
        rel: "dns-prefetch",
        href: this.$CDN
      }, {
        hid: "hotjar-static-preconnect",
        rel: "preconnect",
        href: "https://static.hotjar.com"
      }, {
        hid: "hotjar-script-preconnect",
        rel: "preconnect",
        href: "https://script.hotjar.com"
      }]
    };
  },
  data: function data() {
    return {
      isOnline: true,
      updatingScrollY: false
    };
  },
  computed: {
    theme: function theme() {
      return this.$cookieStore.theme;
    },
    user: function user() {
      return this.$auth.user;
    },
    notificationsStore: function notificationsStore() {
      return useNotificationsStore();
    },
    timeStore: function timeStore() {
      return useTimeStore();
    },
    subscriptionStore: function subscriptionStore() {
      return useSubscriptionStore();
    },
    appStore: function appStore() {
      return useAppStore();
    },
    reportCardStore: function reportCardStore() {
      return useReportCardStore();
    }
  },
  watch: {
    user: function user(val, oldVal) {
      if (!oldVal && val) this.subscriptionStore.hydrate();
    }
  },
  created: function created() {
    if (this.$auth.user && process.browser) this.notificationsStore.checkUnread();
    if (!this.subscriptionStore.hydrated) this.subscriptionStore.hydrate();
    if (process.client && window) {
      this.appStore.$patch({
        isStandaloneApp: window.matchMedia("(display-mode: standalone)").matches
      });
    }
  },
  beforeMount: function beforeMount() {
    var _this = this;
    // socket Listeners
    if (process.server) return true;
    this.timeStore.start();
    this.$socket.on('connect', function () {
      _this.isOnline = true;
      if (_this.$auth.loggedIn) {
        // Subscribe to user changes
        _this.$socket.get('/user/selfSubscribe');
      }
      _this.$socket.get('/user/checkVersion', function (version) {
        if (!_this.appStore.version) {
          _this.appStore.setState({
            key: 'version',
            value: version
          });
        }
        if (_this.appStore.version !== version) {
          _this.$toastStore.add({
            id: 'newVersion',
            title: _this.$t('toast.newVersionTitle'),
            text: _this.$t('toast.newVersionText'),
            autoClose: false,
            closable: true,
            reload: true,
            image: '/images/system/person-info.png'
          });
        }
      });
      _this.$toastStore.remove('offline');
    });
    this.$socket.on('disconnect', function () {
      _this.isOnline = false;

      // Wait 5 sec and show offline notification
      setTimeout(function () {
        if (_this.isOnline) return true;
        _this.$toastStore.add({
          id: 'offline',
          title: _this.$t('toast.offlineTitle'),
          text: _this.$t('toast.offlineText'),
          autoClose: false,
          closable: false,
          icon: 'icon-ic-nosignal'
        });
      }, 5000);
    });

    // Dev tool
    this.$socket.on('tease', function (msg) {
      alert(msg.text);
    });
    if (process.client) {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }
      this.appStore.setState({
        key: 'os',
        value: os
      });
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    window.addEventListener('keydown', this.shortcuts);
    window.addEventListener('resize', this.$cropContent);
    window.addEventListener('resize', this.setVh);

    // Update accesses in real time
    this.$socket.on('newAccessToken', function () {
      _this2.subscriptionStore.hydrate();
    });
    this.$socket.on('reportcard-update', this.reportCardStore.socketUpdate);
    this.$socket.on('notification-new', this.addNotification);
    this.$socket.on('notification-count', this.notificationsStore.setUnread);
    this.setVh();
    this.isSafari();
    this.getScrollbarWidth();
    if (this.$route.hash) {
      this.$nextTick(function () {
        _this2.$scrollTo(_this2.$route.hash, 0);
      });
    }
    if (this.$auth.user) {
      this.$socket.on('updateUser', function (params) {
        var user = Object.assign({}, _this2.$auth.user);
        for (var key in params) {
          user[key] = params[key];
        }
        _this2.$auth.setUser(user);
      });
    }
  },
  methods: {
    // Event handler
    readAllNotifications: function readAllNotifications() {
      this.notificationsStore.markAllread();
      this.notificationsStore.setUnread(false);
    },
    addNotification: function addNotification(data) {
      this.notificationsStore.add(data.notification);
    },
    shortcuts: function shortcuts(e) {
      var inputs = ['INPUT', 'TEXTAREA'];
      if (inputs.indexOf(e.target.tagName.toUpperCase()) >= 0) return true;
      if (e.target.contentEditable == 'true') return true;

      // Single key shortcuts
      if (!e.metaKey && !e.ctrlKey && !e.altKey) {
        // ESC (Close last modal)
        if (e.keyCode === 27) this.$modalityStore.closeLast();

        // M (toggle menu)
        if (e.keyCode === 77) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'cp-menu'
          });
        }

        // U (toggle user menu)
        if (e.keyCode === 85 && this.$auth.user) {
          this.$modalityStore.toggleDropdown({
            name: 'user-menu',
            type: 'dropdown'
          });
        }

        // L (toggle language menu)
        if (e.keyCode === 76) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'locale-toggle'
          });
        }

        // T (toggle theme menu)
        if (e.keyCode === 84) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'theme-toggle'
          });
        }

        // H (toggle help menu)
        if (e.keyCode === 72) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'help-root'
          });
        }

        // A (toggle add menu)
        // if (e.keyCode === 65 && this.$auth.user) {
        //     this.$modalityStore.toggleDropdown({type: 'dropdown', name: 'add-menu'})
        // }

        // C (toggle admin menu)
        if (e.keyCode === 67 && this.$auth.user && this.$auth.user.role && this.$auth.user.role.fullAccess) {
          this.$modalityStore.toggleDropdown({
            type: 'dropdown',
            name: 'admin-menu'
          });
        }

        // N (notifications list)
        if (e.keyCode === 78 && this.$auth.user) {
          this.$modalityStore.toggleModal({
            name: 'notifications',
            type: 'drawer'
          });
        }

        // Backspace
        if (e.keyCode == 8) {
          e.preventDefault();
          if (e.shiftKey) this.$router.forward();else this.$router.back();
        }

        // 1 Go to Media
        if (e.key == '1') {
          e.preventDefault();
          var el = document.getElementById('nav-media');
          if (el) el.click();
        }

        // 2 Go to Lerning
        if (e.key == '2') {
          e.preventDefault();
          var _el = document.getElementById('nav-learning');
          if (_el) _el.click();
        }

        // 3 Go to Jobs
        if (e.key == '3') {
          e.preventDefault();
          var _el2 = document.getElementById('nav-jobs');
          if (_el2) _el2.click();
        }

        // 4 Go to Community
        if (e.key == '4') {
          e.preventDefault();
          var _el3 = document.getElementById('nav-community');
          if (_el3) _el3.click();
        }

        // 5 Go to Video
        if (e.key == '5') {
          e.preventDefault();
          var _el4 = document.getElementById('nav-video');
          if (_el4) _el4.click();
        }

        // 6 Go to About
        if (e.key == '6') {
          e.preventDefault();
          var _el5 = document.getElementById('nav-about');
          if (_el5) _el5.click();
        }
      }
      if (e.keyCode == 82 && e.altKey) {
        e.preventDefault();
        this.$nuxt.refresh();
      }
    },
    setVh: function setVh() {
      if (process.server) return true;
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', "".concat(vh, "px"));
    },
    isSafari: function isSafari() {
      if (process.server) return true;
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) document.body.classList.add('safari');
    },
    getScrollbarWidth: function getScrollbarWidth() {
      // Creating invisible container
      var outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      document.body.appendChild(outer);

      // Creating inner element and placing it in the container
      var inner = document.createElement('div');
      outer.appendChild(inner);

      // Calculating difference between container's full width and the child width
      var scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer);
      this.appStore.$patch({
        scrollbarWidth: scrollbarWidth
      });
      var root = document.documentElement;
      root.style.setProperty('--scrollbar-width', scrollbarWidth + "px");
      return scrollbarWidth;
    }
  },
  destroyed: function destroyed() {
    this.$socket.off('newAccessToken');
    this.$socket.off('reportcard-update', this.reportCardStore.socketUpdate);
    this.$socket.off('notification-new', this.addNotification);
    this.$socket.off('notification-count', this.notificationsStore.setUnread);
    window.removeEventListener('keydown', this.shortcuts);
    window.removeEventListener('resize', this.$cropContent);
    window.removeEventListener('resize', this.setVh);
  }
};